import React, { useCallback, useEffect, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { navigate, useLocation } from '@reach/router'

import Priority from '_components/priority'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { getServiceOrder } from '_modules/service-orders/actions'

import useStyles from './styles'
import EditBudgetButton from './edit-budget-button'
import CancelEditBudgetButton from './cancel-edit-budget-button'
import ManageServicesButton from './manage-services-button'
import RefuseBudgetButton from './refuse-budget-button'
import TemporarySaveBudgetButton from './temporary-save-budget-button'
import SendBudgetButton from './send-budget-button'
import ServiceExecutionButton from './service-execution-button'
import { getReasonsToRefusal } from '_/modules/budget/actions'
import DataNFButton from './data-nf'
import { SendRatingRequestButton } from './send-rating-request-button'
import { STATUS_RATING, STEP_STATUS } from '_/utils/constants/service-order'
import ViewInstallmentsButton from './view-installments-button'
import SaveModelBudgetButton from '_components/budget/navbar/save-model-budget-button'
import ImportBudgetModelButton from '_components/budget/navbar/import-budget-model-button'
import HeaderTitle from '_/components/header-title'

const Navbar = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const { pathname } = useLocation()

  const handleBackNavigation = useCallback(() => {
    dispatch(getServiceOrder(serviceOrder?.get('id'))).then(() => navigate(-1))
  }, [dispatch, serviceOrder])

  useEffect(() => {
    dispatch(getReasonsToRefusal())
  }, [])

  const showRequestRating = useMemo(() => {
    const checkRatingIsNotNecessary =
      !serviceOrder?.isReferaService && serviceOrder?.agency?.get('contractType') === 'SAAS'

    if (checkRatingIsNotNecessary) {
      return false
    }

    return (
      serviceOrder?.requesterRatingFeature &&
      (serviceOrder?.lastStepLogDetails.get('statusRating') === STATUS_RATING.LINK_SENT ||
        serviceOrder?.lastStepLogDetails.get('statusRating') === null) &&
      STEP_STATUS.SERVICE_FINISHED === serviceOrder?.stepStatus
    )
  }, [serviceOrder])

  return (
    <HeaderTitle title={`Pedido ID #${serviceOrder?.id}`} backButtonAction={handleBackNavigation}>
      <Grid className={styles.header}>
        {serviceOrder?.priority && (
          <Grid className={styles.priority}>
            <Priority value={serviceOrder?.priority} />
          </Grid>
        )}

        <Grid className={styles.buttonsWrapper}>
          {showRequestRating && <SendRatingRequestButton />}
          <SaveModelBudgetButton />
          <ServiceExecutionButton />
          <DataNFButton />
          <ManageServicesButton
            serviceOrderId={serviceOrder?.id}
            budgetId={pathname?.split('/')[pathname?.split('/').length - 1]}
          />
          <ViewInstallmentsButton />
          <EditBudgetButton />
          <CancelEditBudgetButton />
          <RefuseBudgetButton />
          <ImportBudgetModelButton />
          <TemporarySaveBudgetButton />
          <SendBudgetButton />
        </Grid>
      </Grid>
    </HeaderTitle>
  )
}

export default Navbar
